exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-works-hire-js": () => import("./../../../src/pages/works/hire.js" /* webpackChunkName: "component---src-pages-works-hire-js" */),
  "component---src-pages-works-index-js": () => import("./../../../src/pages/works/index.js" /* webpackChunkName: "component---src-pages-works-index-js" */),
  "component---src-pages-works-multiple-js": () => import("./../../../src/pages/works/multiple.js" /* webpackChunkName: "component---src-pages-works-multiple-js" */),
  "component---src-pages-works-mvps-js": () => import("./../../../src/pages/works/mvps.js" /* webpackChunkName: "component---src-pages-works-mvps-js" */),
  "component---src-pages-works-prototypes-js": () => import("./../../../src/pages/works/prototypes.js" /* webpackChunkName: "component---src-pages-works-prototypes-js" */),
  "component---src-pages-works-redesigns-js": () => import("./../../../src/pages/works/redesigns.js" /* webpackChunkName: "component---src-pages-works-redesigns-js" */)
}

