/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import ReactGA from 'react-ga';
import $ from 'jquery';
import {ContextProvider} from './src/ContextProvider';

ReactGA.initialize('UA-17021718-10');

export const wrapRootElement = ({element}) => <ContextProvider>{element}</ContextProvider>;
