/* eslint-disable react/prop-types */
import React, {createContext, useContext, useEffect, useState} from 'react';

// Step 1: Create a context
const MyContext = createContext({
    a1: null,
    a2: null,
    resetStorageValues: () => {},
    setA1Handler: () => {},
    setA2Handler: () => {},
});

// Step 2: Create a context provider component
export const ContextProvider = ({children}) => {
    // State to be shared via the context
    const [a1, setA1] = useState(null);
    const [a2, setA2] = useState(null);

    const getLocalStorageValues = () => {
        if (window.localStorage.getItem('q1')) {
            setA1(JSON.parse(window.localStorage.getItem('q1')));
        }
        if (window.localStorage.getItem('q2')) {
            setA2(JSON.parse(window.localStorage.getItem('q2')));
        }
    };

    const resetStorageValues = () => {
        localStorage.removeItem('q1');
        localStorage.removeItem('q2');
        setA1(null);
        setA2(null);
    };

    const setA1Handler = val => {
        window.localStorage.setItem('q1', JSON.stringify(val));
        setA1(val);
    };

    const setA2Handler = val => {
        window.localStorage.setItem('q2', JSON.stringify(val));
        setA2(val);
    };

    useEffect(() => {
        getLocalStorageValues();
    }, []);

    return (
        <MyContext.Provider
            value={{
                a1,
                a2,
                resetStorageValues,
                setA1Handler,
                setA2Handler,
            }}
        >
            {children}
        </MyContext.Provider>
    );
};

export const useMyContext = () => useContext(MyContext);
